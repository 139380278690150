import React, { useEffect, useState } from 'react';
import { AccessRights, ModuleIdentifiers } from '../../../../Core/Authentication/ModuleAccess';
import { Typography } from '@material-ui/core';
import WidgetContainer from '../../../../Shared/WidgetContainer/WidgetContainer';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Link from '../../../../Shared/Link/Link';
import ServiceForm from './ServiceForm/ServiceForm';
import ServiceMenu from './ServiceMenu/ServiceMenu';
import { formatDateWithTime } from '../../../../Shared/Formatting/formatDate';
import { ServiceDto, useGetServiceList } from '../Forum.api';
import { useStyles } from './Service.styles';
import AccessGuard from '../../../../Core/Authentication/AccessGuard/AccessGuard';
import IconButton from '../../../../Shared/IconButton/IconButton';
import BookOnService from './BookOnOrder/BookOnService';

interface IService {
  setKey?: React.Dispatch<React.SetStateAction<number>>;
}

const Service = (props: IService) => {
  const classes = useStyles();
  const { isLoading, services, getServiceList } = useGetServiceList();
  const [openAddService, setOpenAddService] = useState(false);
  const [editService, setEditService] = useState<ServiceDto | undefined>();

  useEffect(() => {
    getServiceList();
  }, [getServiceList]);

  return (
    <WidgetContainer
      loading={isLoading}
      label={`Service (${services?.length ?? 0})`}
      actions={
        <>
          <AccessGuard module={ModuleIdentifiers.Service} accessRights={AccessRights.Write}>
            <IconButton
                aria-label="Lägg till service"
                className={classes.remove}
                onClick={() => setOpenAddService(true)}
              >
                <AddIcon />
            </IconButton>
            <ServiceForm
              title={'Lägg till Service'}
              open={openAddService}
              setOpen={setOpenAddService}
              onUpsert={() => getServiceList()}
            />
          </AccessGuard>
        </>
      }
    >
      <ul className={classes.list}>
        {services?.map((service) => (
          <li key={service.id} className={classes.listItem}>
            <div className={classes.messageContainer}>
              <div className={classes.message} onClick={() => setEditService(service) }>
                <Typography className={classes.customer}>
                  {formatDateWithTime(service.created) + ' '}{service.authorName}
                </Typography>
                <Typography>
                  <Link
                    module={ModuleIdentifiers.Service}
                    accessRights={AccessRights.Write}
                    to={`/customers/${service.customerId}/facility/${service.facilityId}`}
                  >
                    {service.customerName}{service.customerName !== service.facilityName ?  (' - ' + service.facilityName + ', ') : ", "} {service.facilityCity}
                  </Link>
                </Typography>
                <Typography>
                  {service.coreSystemIdentifier} - {service.coreSystemMainProductName} - {service.coreSystemPlacement}
                </Typography>
                <Typography>
                  {service.facilityAdress + ', ' + service.facilityPostalCode + ', ' + service.facilityCity}
                </Typography>
                <Typography>
                  {service.contactPerson != null ? service.contactPerson.firstname + ' ' + service.contactPerson.lastname  + ' ' + service.contactPerson.phoneNumber : ''}
                </Typography>
                <Typography>{service.description}</Typography>
              </div>
              <div className={classes.dropdown}>
                <ServiceMenu
                  onChange={() => {
                    getServiceList();
                    props.setKey && props.setKey((prev) => prev + 1);
                  }}
                  service={service}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
      {editService &&
        <BookOnService
          title="Boka"
          serviceId={editService.id}
          customerId={editService.customerId}
          customerName={editService.customerName}
          facilityId={editService.facilityId}
          coreSystemId={editService.coreSystemId}
          description={editService.description}
          open={editService != null}
          setOpen={() => setEditService(undefined)}
          onSave={() => {
            getServiceList();
            props.setKey && props.setKey((prev) => prev + 1);
          }}
        />
      }
    </WidgetContainer>
  );
};

export default Service;
