import React, { useEffect, useState } from 'react';
import { TableHead, TableBody, TableRow, TableCell, useMediaQuery } from '@material-ui/core';
import { Table } from '../../../../../Shared/Table/Table';
import { IAuditListItem } from '../../Audit.api';
import useTable from '../../../../../Shared/Table/useTable';
import TablePaginationWithDatasource from '../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import SearchInput from '../../../../../Shared/InputFields/SearchInput/SearchInput';
import formatDate from '../../../../../Shared/Formatting/formatDate';
import SortButton from '../../../../../Shared/Table/SortButton/SortButton';
import IPaginatedResponse from '../../../../../Shared/Api/response/IPaginatedResponse';
import moment from 'moment';
import { MobileTable } from '../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import theme from '../../../../../Styles/Theme';
import IPaginatedRequest from '../../../../../Shared/Api/request/IPaginatedRequest';
import useStyles from './AuditListComponent.styles';
import ContractlessMenu from '../ContractlessMenu/ContractlessMenu';
import NoContactAuditMenu from '../NoContactAuditMenu/NoContactAuditMenu';
import { useHistory } from 'react-router';
import { Select } from '../../../../../Shared/Select';
import Datepicker from '../../../../../Shared/InputFields/DatePicker/Datepicker';

export interface IAuditListComponentProps {
  audits?: IPaginatedResponse<IAuditListItem>;
  isLoading?: boolean;
  onTableChange?: (tableProps: IPaginatedRequest, dateFrom: Date | null, dateTo: Date | null, status: string | undefined) => void;
  emptyMessage?: string;
  label?: string;
  editPath: string;
  isContractlessList?: boolean;
  isNoContactList?: boolean;
  loadList?: () => void;
}

interface ITimeFilterOption {
  label: string;
  value: string;
}

const timeFilterOptions: ITimeFilterOption[] = [
  { label: "Grön", value: "green"},
  { label: "Gul", value: "yellow"},
  { label: "Röd", value: "red"}
]


const AuditListComponent = (props: IAuditListComponentProps) => {
  const { isLoading, audits, onTableChange, emptyMessage, label, editPath, loadList } = props;
  const isContractlessOrNoContactList = props.isContractlessList || props.isNoContactList;
  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    query,
    setQuery,
    paginatedRequest,
  } = useTable();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const classes = useStyles();
  const [timeFilter, setTimeFilter] = useState<ITimeFilterOption>();
  const [createdFrom, setCreatedFrom] = useState<Date | null>(null);
  const [createdTo, setCreatedTo] = useState<Date | null>(null);

  useEffect(() => {
    onTableChange && onTableChange(paginatedRequest, createdFrom, createdTo, timeFilter?.value);
  }, [createdFrom, createdTo, onTableChange, paginatedRequest, timeFilter]);

  function getClasses(item: IAuditListItem) {
    var mDiff = moment(item.nextAudit).diff(new Date(), 'month');
    var dDiff = moment(item.nextAudit).diff(new Date(), 'day');
    if (dDiff > 0) {
      return classes.green;
    } else if ((mDiff*-1) <= 3) {
      return classes.yellow;
    } else {
      return classes.red;
    }
  }

  const onDatePickerChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setCreatedFrom(start);
    setCreatedTo(end);
  };

  const menuButton = (audit: IAuditListItem) => (
    <>
      {props.isContractlessList && <ContractlessMenu auditInfo={audit} mobileView={mobileView} loadList={loadList} />}
      {props.isNoContactList && <NoContactAuditMenu auditInfo={audit} mobileView={mobileView} loadList={loadList} />}
    </>
  );

  const redirect = (audit: IAuditListItem) => {
    history.push(`${editPath}/${audit.customerId}/${audit.coreSystemId}`);
  };

  return (
    <Table
      label={`${label} (${audits?.totalCount ?? 0})`}
      filters={
        <>
          <SearchInput
            defaultValue={query}
            type="search"
            placeholder="Sök"
            value={query}
            onChange={(search) => setQuery(search)}
          />
          <Select
            inputFieldProps={{ 'aria-label': 'Typ', placeholder: 'Tid' }}
            selectProps={{
              options: timeFilterOptions,
              isClearable: true,
              onChange: (x) => (x ? setTimeFilter(x as ITimeFilterOption) : setTimeFilter(undefined)),
            }}
          />
          <Datepicker
            selectsRange
            isClearable
            inFilter
            dateFormat={'yy-MM-dd'}
            startDate={createdFrom}
            endDate={createdTo}
            placeholderText="Skapad fr.o.m - t.o.m"
            onChange={onDatePickerChange}
          />
        </>
      }
      mobile={mobileView}
      customSize={{
        fullsize: true,
      }}
      pagination={
        audits && (
          <TablePaginationWithDatasource
            datasource={audits}
            onChangePage={setPageNumber}
            onChangeRowsPerPage={setPageSize}
          />
        )
      }
      empty={{
        empty: audits && audits.totalCount <= 0 ? true : false,
        message: emptyMessage,
      }}
      loading={{
        loading: !!isLoading,
        skeletonRows: pageSize,
      }}
      minWidth
    >
      {mobileView ? (
        <>
          {audits?.items.map((audit) => (
            <MobileTable
              key={audit.agreementId}
              label={(audit.customerName === audit.facilityName ? audit.customerName : audit.customerName + ' - ' + audit.facilityName)}
              action={menuButton(audit)}
            >
              <MobileTableRow label="Kundnummer" value={audit.facilityNumber} />
              <MobileTableRow label="Kundnamn" value={(audit.customerName === audit.facilityName ? audit.customerName : audit.customerName + ' - ' + audit.facilityName)} />
              <MobileTableRow label="Adress" value={audit.address + ", " + audit.postalCode} />
              <MobileTableRow label="Stad" value={audit.address} />
              <MobileTableRow label="Systemtyp" value={audit.systemType} />
              <MobileTableRow
                label="Systemnamn"
                value={audit.description}
                link={`${editPath}/${audit.customerId}/${audit.coreSystemId}`}
              />
              <MobileTableRow label="Larmklass" value={audit.alarmClass ? "Larmklass " + audit.alarmClass : "-"} />
              {!isContractlessOrNoContactList && <MobileTableRow label="Avtal" value={audit.agreement} />}
              {/*!isContractlessOrNoContactList && (
                <MobileTableRow label="Startdatum för avtal" value={formatDate(audit.startDate)} />
              )*/}
              <MobileTableRow label="Intervall" value={audit.interval + " år"} />
              <MobileTableRow label="Senaste revision" value={formatDate(audit.lastAudit, '-')} />
              <MobileTableRow
                label="Nästa revision"
                value={
                  <span className={`${classes.nextAudit} ${getClasses(audit)}`}>{formatDate(audit.nextAudit)}</span>
                }
              />
            </MobileTable>
          ))}
        </>
      ) : (
        <>
          <TableHead>
            <TableRow>
              {isContractlessOrNoContactList && (
                <TableCell>
                  <span className={'sr-only'}>Knappar</span>
                </TableCell>
              )}
              <TableCell>
                Kundnummer
                <SortButton
                  property="customerNumber"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Kundnamn
                <SortButton
                  property="customerName"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Adress
                <SortButton
                  property="address"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Stad
                <SortButton
                  property="city"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Systemtyp
                <SortButton
                  property="systemType"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Systemnamn
                <SortButton
                  property="description"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Larmklass
              </TableCell>
              {!isContractlessOrNoContactList && (
                <TableCell>
                  Avtal
                  <SortButton
                    property="agreement"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
              )}
              {/*!isContractlessOrNoContactList && (
                <TableCell>
                  Startdatum för avtal
                  <SortButton
                    property="startDate"
                    sortProperty={sortProperty}
                    sortDirection={sortDirection}
                    onSort={toggleSort}
                  />
                </TableCell>
              )*/}
              <TableCell>
                Intervall
              </TableCell>
              <TableCell>
                Senaste revision
                <SortButton
                  property="lastAudit"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              <TableCell>
                Nästa revision
                <SortButton
                  property="nextAudit"
                  sortProperty={sortProperty}
                  sortDirection={sortDirection}
                  onSort={toggleSort}
                />
              </TableCell>
              {/*<TableCell>
                Status
              </TableCell>*/}
            </TableRow>
          </TableHead>
          <TableBody>
            {audits?.items.map((audit) => (
              <TableRow key={audit.agreementId} className={classes.pointerCursor} hover tabIndex={-1}>
                {isContractlessOrNoContactList && menuButton(audit)}
                <TableCell onClick={() => redirect(audit)}>{audit.facilityNumber}</TableCell>
                <TableCell onClick={() => redirect(audit)}>{(audit.customerName === audit.facilityName ? audit.customerName : audit.customerName + ' - ' + audit.facilityName)}</TableCell>
                <TableCell onClick={() => redirect(audit)}>{audit.address + ", " + audit.postalCode}</TableCell>
                <TableCell onClick={() => redirect(audit)}>{audit.city}</TableCell>
                <TableCell onClick={() => redirect(audit)}>{audit.systemType}</TableCell>
                <TableCell className={classes.font} onClick={() => redirect(audit)}>
                  {audit.description}
                </TableCell>
                <TableCell onClick={() => redirect(audit)}>{audit.alarmClass ? "Larmklass " + audit.alarmClass : "-"}</TableCell> 
                {!isContractlessOrNoContactList && (
                  <TableCell onClick={() => redirect(audit)}>{`${audit.facilityId}-${audit.agreement}`}</TableCell>
                )}
                {/*!isContractlessOrNoContactList && (
                  <TableCell onClick={() => redirect(audit)}>{formatDate(audit.startDate)}</TableCell>
                )*/}
                <TableCell onClick={() => redirect(audit)}>{audit.interval + " år"}</TableCell>
                <TableCell onClick={() => redirect(audit)}>{formatDate(audit.lastAudit, '-')}</TableCell>
                <TableCell className={`${classes.nextAudit} ${getClasses(audit)}`} onClick={() => redirect(audit)}>
                  {formatDate(audit.nextAudit)}
                </TableCell>
                {/*<TableCell>
                  {audit.hasBookedRevision ? "Bokad" : audit.isOnTodoList ? "På att göra" : "-"}
                </TableCell>*/}
              </TableRow>
            ))}
          </TableBody>
        </>
      )}
    </Table>
  );
};

export default AuditListComponent;
