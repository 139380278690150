import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { Table } from '../../../../../Shared/Table/Table';
import SearchInput from '../../../../../Shared/InputFields/SearchInput/SearchInput';
import TablePaginationWithDatasource from '../../../../../Shared/Table/TablePaginationWithDatasource/TablePaginationWithDatasource';
import SortButton from '../../../../../Shared/Table/SortButton/SortButton';
import Link from '../../../../../Shared/Link/Link';
import {
  useGetCustomers,
  searchCustomerOptionList,
  SearchCustomerOption,
  ICustomerWithAddress,
} from '../../Customers.api';
import useTable from '../../../../../Shared/Table/useTable';
import AccessGuard from '../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../Core/Authentication/ModuleAccess';
import theme from '../../../../../Styles/Theme';
import { MobileTable } from '../../../../../Shared/Table/MobileTable/MobileTable';
import { MobileTableRow } from '../../../../../Shared/Table/MobileTable/MobileTableRow/MobileTableRow';
import Select, { ISelectOption } from '../../../../../Shared/Select/Select';
import { SortDirection } from '../../../../../Shared/Api/request/IPaginatedRequest';
import IPaginatedResponse from '../../../../../Shared/Api/response/IPaginatedResponse';
import { useStyles } from '../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import CopyLink from '../../../../../Shared/CopyLink/CopyLink';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import { getCustomerListSetting, saveCustomerListSetting } from './CustomerListSettings';
import IconButton from '../../../../../Shared/IconButton/IconButton';
import Container from '../../../../../Shared/Container/Container';

interface ICustomerSearchSelectOption extends ISelectOption {
  option: SearchCustomerOption;
}

const searchOptions: ICustomerSearchSelectOption[] = searchCustomerOptionList.map((x) => ({
  label: x.name,
  value: x.option.toString(),
  option: x.option,
}));

const tableColumn = 5; //Not sure if hard coded column is good ??

const CustomerList = () => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const customerParams = params.get('query');
  const typeParams = params.get('type');
  const { isLoading, getCustomers } = useGetCustomers();
  const {
    toggleSort,
    sortProperty,
    sortDirection,
    pageSize,
    setPageSize,
    setPageNumber,
    setQuery,
    query,
    paginatedRequest,
  } = useTable(100, 'name', SortDirection.Ascending, customerParams ?? '');
  const [emptyMessage, setEmptyMessage] = useState('');
  const [selectedSearchOption, setSelectedSearchOption] = useState<ICustomerSearchSelectOption | undefined>(
    searchOptions.find((x) => x.value === typeParams),
  );
  const [customers, setCustomers] = useState<IPaginatedResponse<ICustomerWithAddress>>();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [hideParentCustomer, setHideParentCustomer] = useState<boolean>(getCustomerListSetting('hideParentCustomer'));
  const [showProspects, setShowProspects] = useState<boolean>(getCustomerListSetting('showProspects'));
  const classes = useStyles();

  const getList = useCallback(async () => {
    if (paginatedRequest.query && (paginatedRequest.query.length > 1 || selectedSearchOption?.option === SearchCustomerOption.system)) {
      const response = await getCustomers({
        ...paginatedRequest,
        option: selectedSearchOption ? selectedSearchOption.option : undefined,
        includeProspect: showProspects,
      });

      setCustomers(() => {
        const word = paginatedRequest?.query?.toLowerCase().trim();
        if (word !== undefined) {
          const preciseMatches = response?.items.filter((x) => x.name.toLowerCase().trim().includes(word));

          if (preciseMatches && preciseMatches.length > 0 && preciseMatches !== undefined) {
            response.items = response.items.filter((x) => !x.name.toLowerCase().trim().includes(word));
            response.items.unshift(...preciseMatches);
          }

          return { ...response };
        }
      });

      if (response?.totalCount <= 0) {
        setEmptyMessage('Filtreringen gav inga resultat');
      }
    } else {
      setCustomers(undefined);
      setEmptyMessage('Filtreringen gav inga resultat');
    }
  }, [paginatedRequest, getCustomers, selectedSearchOption, showProspects]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleTableClick = (customer: ICustomerWithAddress) => {
    history.push({
      pathname: history.location.pathname,
      search: selectedSearchOption
        ? `?query=${query}&type=${selectedSearchOption.value.toString()}`
        : `?query=${query}`,
    });
    history.push(`${history.location.pathname}/${customer.id}`);
  };

  const handleFacilityTableClick = (customer: ICustomerWithAddress, facilityId: number) => {
    history.push({
      pathname: history.location.pathname,
      search: selectedSearchOption
        ? `?query=${query}&type=${selectedSearchOption.value.toString()}`
        : `?query=${query}`,
    });
    history.push(`${history.location.pathname}/${customer.id}/facility/${facilityId}`);
  };
  const handleSystemTableClick = (customer: ICustomerWithAddress, facilityId: number, systemId: number) => {
    history.push({
      pathname: history.location.pathname,
      search: selectedSearchOption
        ? `?query=${query}&type=${selectedSearchOption.value.toString()}`
        : `?query=${query}`,
    });
    history.push(`${history.location.pathname}/${customer.id}/facility/${facilityId}/system/${systemId}`);
  };

  const customerCount = () => {
    var count = 0;
    if (hideParentCustomer) {
      customers?.items.forEach((i) => {
        count += i.customerFacilities.length;
      });
    } else {
      count = customers?.totalCount ?? 0;
    }
    return count > 0 ? '(' + count + ')' : '';
  };

  return (
    <>
      <Container
        customSize={{ fullsize: true }}
        label={`Kunder ${customerCount()}`}
        customIconHeader={
          <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
            <>
              <IconButton
                aria-label="Skapa ny kund"
                onClick={() => {
                  history.push(`${history.location.pathname}/create`);
                }}
              >
                <AddIcon />
              </IconButton>
              
              {(query || selectedSearchOption) && (
                <CopyLink
                  color="white"
                  root={history.location.pathname}
                  parameters={[
                    { param: 'query', value: query },
                    { param: 'type', value: selectedSearchOption?.value.toString() },
                  ]}
                />
              )}
            </>
          </AccessGuard>
        }
      >
        <Table
          filters={
            <>
              <SearchInput
                defaultValue={query}
                type="search"
                placeholder="Sök"
                onChange={(search) => { setQuery(search)}}
              />
              <Select
                inputFieldProps={{ 'aria-label': 'Typ av sök', placeholder: 'Välj typ' }}
                selectProps={{
                  isClearable: true,
                  value: selectedSearchOption,
                  onChange: (selectedValues) => {
                    setSelectedSearchOption(selectedValues as ICustomerSearchSelectOption);
                  },
                  options: searchOptions,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="hideParentCustomer"
                    color="primary"
                    checked={hideParentCustomer}
                    onChange={(_, checked) => {
                      setHideParentCustomer(checked);
                      saveCustomerListSetting('hideParentCustomer', checked);
                    }}
                  />
                }
                label="Dölj huvudkund"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="showProspects"
                    color="primary"
                    checked={showProspects}
                    onChange={(_, checked) => {
                      setShowProspects(checked);
                      saveCustomerListSetting('showProspects', checked);
                    }}
                  />
                }
                label="Visa prospects"
              />
            </>
          }
          pagination={
            customers && (
              <TablePaginationWithDatasource
                datasource={customers}
                onChangePage={setPageNumber}
                onChangeRowsPerPage={setPageSize}
              />
            )
          }
          empty={{
            empty: customers && customers.totalCount <= 0 ? true : false,
            message: emptyMessage,
          }}
          loading={{
            loading: isLoading,
            skeletonRows: pageSize,
          }}
          customSize={{ lg: 6, md: 12, fullsize: true }}
          mobile={mobileView}
        >
          {mobileView ? (
            <>
              {customers?.items.map((customer) => (
                <>
                  {(!hideParentCustomer || (hideParentCustomer && customer.facilityCount < 1)) && (
                    <MobileTable
                      key={customer.id}
                      icon={<HomeIcon style={{ marginBottom: '-5px' }} />}
                      label={customer.name}
                      link={`${history.location.pathname}/${customer.id}`}
                    >
                      <MobileTableRow label="Kundnummer" value={customer.customerNumber} />
                      <MobileTableRow
                        label="Namn"
                        value={
                          <Link
                            to={`${history.location.pathname}/${customer.id}`}
                            module={ModuleIdentifiers.Customer}
                            accessRights={AccessRights.Read}
                          >
                            {customer.name}
                          </Link>
                        }
                      />
                      <MobileTableRow label="Adress" value={customer.address} />
                      <MobileTableRow label="Ort" value={customer.city} />
                    </MobileTable>
                  )}
                  {customer.customerFacilities.length > 0 &&
                    customer.customerFacilities.map((facility, index) => (
                      <MobileTable
                        key={customer.id}
                        label={`${facility.facilityNumber} - ${facility.facilityName}`}
                        link={`${history.location.pathname}/${customer.id}/facility/${facility.facilityId}`}
                      >
                        <MobileTableRow label="Kundnummer" value={facility.facilityNumber} />
                        <MobileTableRow
                          label="Kundnamn"
                          value={
                            <Link
                              to={`${history.location.pathname}/${customer.id}`}
                              module={ModuleIdentifiers.Customer}
                              accessRights={AccessRights.Read}
                            >
                              {customer.name}
                            </Link>
                          }
                        />
                        <MobileTableRow
                          label="Platsnamn"
                          value={
                            <Link
                              to={`${history.location.pathname}/${customer.id}/facility/${facility.facilityId}`}
                              module={ModuleIdentifiers.Customer}
                              accessRights={AccessRights.Read}
                            >
                              {facility.facilityName}
                            </Link>
                          }
                        />
                        <MobileTableRow label="Adress" value={facility.facilityAddress} />
                        <MobileTableRow label="Ort" value={facility.facilityCity} />
                      </MobileTable>
                    ))}
                </>
              ))}
            </>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  <AccessGuard
                    module={ModuleIdentifiers.Customer}
                    accessRights={AccessRights.Full}
                    fallback={<TableCell className={classes.narrowColumn}></TableCell>}
                  >
                    <TableCell>
                      <span className={'sr-only'}>Knappar</span>
                    </TableCell>
                  </AccessGuard>
                  <TableCell width="75">
                    Kundnr
                    <SortButton
                      property="customerNumber"
                      sortProperty={sortProperty}
                      sortDirection={sortDirection}
                      onSort={toggleSort}
                    />
                  </TableCell>
                  <TableCell width="300">
                    Namn
                    <SortButton
                      property="name"
                      sortProperty={sortProperty}
                      sortDirection={sortDirection}
                      onSort={toggleSort}
                    />
                  </TableCell>
                  <TableCell>
                    Adress
                    <SortButton
                      property="address"
                      sortProperty={sortProperty}
                      sortDirection={sortDirection}
                      onSort={toggleSort}
                    />
                  </TableCell>
                  <TableCell>
                    Ort
                    <SortButton
                      property="city"
                      sortProperty={sortProperty}
                      sortDirection={sortDirection}
                      onSort={toggleSort}
                    />
                  </TableCell>
                  {selectedSearchOption === undefined && <TableCell>Anteckning</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {customers?.items.map((customer) => (
                  <>
                    {!hideParentCustomer && (
                      <TableRow className={classes.pointerCursor} hover tabIndex={-1} key={customer.id}>
                        <>
                          <>
                            <AccessGuard
                              module={ModuleIdentifiers.Customer}
                              accessRights={AccessRights.Full}
                              fallback={<TableCell className={classes.narrowColumn}></TableCell>}
                            >
                              <TableCell className={classes.narrowColumn} onClick={() => handleTableClick(customer)}>
                                <HomeIcon />
                              </TableCell>
                            </AccessGuard>
                            <TableCell className={classes.linkFont} onClick={() => handleTableClick(customer)}>
                              {customer.customerNumber}
                            </TableCell>
                            <TableCell className={classes.linkFont} onClick={() => handleTableClick(customer)}>
                              {customer.name}
                            </TableCell>
                            <TableCell onClick={() => handleTableClick(customer)}>
                              {(customer.facilityCount === 1 &&
                                customer.customerFacilities?.length === 1 &&
                                customer.customerFacilities[0].facilityAddress) ||
                                customer.address}
                            </TableCell>
                            <TableCell onClick={() => handleTableClick(customer)}>
                              {(customer.facilityCount === 1 &&
                                customer.customerFacilities?.length === 1 &&
                                customer.customerFacilities[0].facilityCity) ||
                                customer.city}
                            </TableCell>
                            {selectedSearchOption === undefined && (
                              <TableCell>
                                {(customer.facilityCount === 1 &&
                                  customer.customerFacilities?.length === 1 &&
                                  customer.customerFacilities[0].internalNote) ||
                                  '-'}
                              </TableCell>
                            )}
                          </>
                        </>
                      </TableRow>
                    )}
                    {(hideParentCustomer || customer.facilityCount > 1) &&
                      selectedSearchOption?.option !== SearchCustomerOption.systemProducts &&
                      customer.customerFacilities.map((facility, index) => (
                        <TableRow
                          className={`${classes.pointerCursor} ${
                            index + 1 === customer.customerFacilities.length &&
                            customer.customerFacilities.length === customer.facilityCount &&
                            classes.borderBottom
                          }`}
                          hover
                          tabIndex={-1}
                          key={facility.facilityId}
                        >
                          <TableCell
                            onClick={() => handleFacilityTableClick(customer, facility.facilityId)}
                          ></TableCell>
                          <TableCell onClick={() => handleFacilityTableClick(customer, facility.facilityId)}>
                            {facility.facilityNumber}
                          </TableCell>
                          <TableCell
                            className={classes.linkFont}
                            onClick={() => handleFacilityTableClick(customer, facility.facilityId)}
                          >
                            {customer.name === facility.facilityName
                              ? facility.facilityName
                              : `${customer.name} - ${facility.facilityName}`}
                          </TableCell>
                          <TableCell onClick={() => handleFacilityTableClick(customer, facility.facilityId)}>
                            {facility.facilityAddress}
                          </TableCell>
                          <TableCell onClick={() => handleFacilityTableClick(customer, facility.facilityId)}>
                            {facility.facilityCity}
                          </TableCell>
                          {selectedSearchOption === undefined && (
                            <TableCell onClick={() => handleFacilityTableClick(customer, facility.facilityId)}>
                              {facility.internalNote}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    {(hideParentCustomer || customer.facilityCount > 1) &&
                      selectedSearchOption?.option === SearchCustomerOption.systemProducts && (
                        <>
                          <TableRow className={classes.fakeTableHead} hover tabIndex={-1} key={'-1'}>
                            <TableCell></TableCell>
                            <TableCell>SID</TableCell>
                            <TableCell>Systemtyp</TableCell>
                            <TableCell>Systemidentifikation</TableCell>
                            <TableCell>Namn huvudprodukt</TableCell>
                          </TableRow>
                          {customer.customerFacilities.map((facility, index) => {
                            return facility.coreSystems?.map((coreSystem) => (
                              <TableRow
                                className={`${classes.pointerCursor} ${
                                  index + 1 === customer.customerFacilities.length &&
                                  customer.customerFacilities.length === customer.facilityCount &&
                                  classes.borderBottom
                                }`}
                                hover
                                tabIndex={-1}
                                key={facility.facilityId}
                                onClick={() =>
                                  handleSystemTableClick(customer, facility.facilityId, coreSystem.coreSystemId)
                                }
                              >
                                <TableCell></TableCell>
                                <TableCell>SID: {coreSystem.coreSystemId}</TableCell>
                                <TableCell className={classes.linkFont}>{coreSystem.systemTypeName}</TableCell>
                                <TableCell>{coreSystem.coreSystemIdentifier}</TableCell>
                                <TableCell>{coreSystem.mainProductName}</TableCell>
                              </TableRow>
                            ));
                          })}
                        </>
                      )}
                    {customer.customerFacilities.length !== customer.facilityCount && !hideParentCustomer && (
                      <TableRow className={`${classes.pointerCursor} ${classes.borderBottom}`}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell colSpan={tableColumn - 2}>
                          {customer.facilityCount - customer.customerFacilities.length} dolda platser...
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </Container>
    </>
  );
};

export default CustomerList;
