import { useCallback } from 'react';
import { useApiPost, useApiPut, useApiGet, useApiDelete } from '../../../Core/Api/Api';
import { ICreateResult } from '../../../Shared/Api/response/ICreateResult';
import ValidationErrorResultType from '../../../Shared/Api/response/ValidationErrorResultType';
import { ICoreSystem } from '../Customers/Components/Facilities/Facilities.api';

export interface IGetCompanyUserMessagesResult {
  id: number;
  description: string;
  senderName: string;
  senderCompanyUserId?: number;
  allCompanyUsersSelected: boolean;
  recipientCompanyUserIds: number[];
  recipientNames: string[];
  created: Date;
}

export function useGetCompanyUserMessages() {
  const { isLoading, response, get } = useApiGet<IGetCompanyUserMessagesResult[]>('/api/forum/companyusermessage/list');

  return { isLoading, companyUserMessages: response, getCompanyUserMessages: get };
}

export interface IUpsertCompanyUserMessageRequestBody {
  companyUserIds: string[];
  description: string;
  allCompanyUsersSelected: boolean;
}

export interface IUpsertCompanyUserMessageRequestValidationErrors
  extends ValidationErrorResultType<IUpsertCompanyUserMessageRequestBody> {}

export function useUpsertCompanyUserMessage() {
  const { isLoading: isCreateLoading, post } = useApiPost<
    ICreateResult<IUpsertCompanyUserMessageRequestValidationErrors>,
    IUpsertCompanyUserMessageRequestBody
  >(`/api/forum/companyusermessage/`);

  const { isLoading: isUpdateLoading, put } = useApiPut<
    IUpsertCompanyUserMessageRequestValidationErrors,
    IUpsertCompanyUserMessageRequestBody
  >(`/api/forum/companyusermessage/`);

  async function upsert(command: IUpsertCompanyUserMessageRequestBody, id?: string) {
    if (id) {
      const result = await put(command, id);

      return result ? result : id;
    }

    return post(command);
  }

  return {
    isLoading: isCreateLoading || isUpdateLoading,
    upsert: upsert,
  };
}

export function useCreateTodoFromCompanyUserMessage() {
  const { isLoading, post } = useApiPost<any>('/api/forum/companyusermessage/');

  const handleCreateTodoFromCompanyUserMessage = useCallback(
    async (companyUserMessageId: string) => {
      return await post(undefined, `${companyUserMessageId}/todo`);
    },
    [post],
  );

  return {
    isLoading,
    post: handleCreateTodoFromCompanyUserMessage,
  };
}

export interface ServiceDto {
  id: number;
  customerId: number;
  customerName: string;
  facilityId: number;
  facilityName: string;
  facilityCity: string;
  facilityAdress: string;
  facilityPostalCode: string;
  coreSystemId: number; 
  coreSystemIdentifier: string; 
  coreSystemMainProductName: string; 
  coreSystemPlacement: string; 
  customerContactId?: number;
  description: string;
  authorName: string;
  created: Date;
  contactPerson: ICustomerContactData;
}

export interface ICustomerContactData {
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  position: string;
}

export function useGetServiceList() {
  const { isLoading, response, get } = useApiGet<ServiceDto[]>('/api/forum/service/list');

  return {
    isLoading,
    services: response,
    getServiceList: get,
  };
}

export interface IUpsertServiceCommand {
  customerId: number;
  facilityId: number;
  coreSystemId: number;
  customerContactId?: number;
  contactPerson?: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    position: string;
  };
  description: string;
}

export interface IUpsertServiceCommandValidationErrors extends ValidationErrorResultType<IUpsertServiceCommand> {}

export function useUpsertService() {
  const { isLoading: isLoadingPost, post } = useApiPost<
    ICreateResult<IUpsertServiceCommandValidationErrors>,
    IUpsertServiceCommand
  >(`/api/forum/service`);

  const { isLoading: isLoadingPut, put } = useApiPut<
    ICreateResult<IUpsertServiceCommandValidationErrors>,
    IUpsertServiceCommand
  >(`/api/forum/service/`);

  async function upsert(body: IUpsertServiceCommand, id?: number) {
    if (id) {
      return await put(body, id.toString());
    }

    return await post(body);
  }

  return {
    isUpsertLoading: isLoadingPost || isLoadingPut,
    upsert,
  };
}

export interface IBookOnServiceCommand {
  description: string;
  jobDescription?: string;
  coreSystemId?: number;
  totalPrice: number;
  companyUserId: number;
  startDate: Date;
  endDate: Date;
}

export function useBookInstallationOnService(serviceId: number) {
  const { isLoading, post } = useApiPost<any, IBookOnServiceCommand>(`/api/forum/service/${serviceId}/order`);

  return {
    isLoading,
    post,
  };
}

export function useDeleteCompanyMessage() {
  const { isLoading, delete: deleteRequest } = useApiDelete(`/api/forum/`);

  const deleteCompanyMessage = useCallback(
    async (companyMessageId: string) => {
      return await deleteRequest(undefined, companyMessageId);
    },
    [deleteRequest],
  );

  return {
    isLoading,
    deleteCompanyMessage,
  };
}
