import React, { useState, useEffect, useMemo } from 'react';
import useInputState from '../../../Shared/Hooks/UseInputState/UseInputState';
import { InputField } from '../../../Shared/InputFields/InputField/InputField';
import { Table } from '../../../Shared/Table/Table';
import Button from '../../../Shared/Button/Button';
import { TableHead, TableRow, TableCell, TableBody, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import IconButton from '../../../Shared/IconButton/IconButton';
import { RecentActorsRounded } from '@material-ui/icons';
import {
  CoreSystemRegistrationStatus,
  CoreSystemRegistrationStatusName,
} from '../../Modules/Customers/Components/Facilities/CoreSystems/CoreSystem.api';
import EmailField from '../../../Shared/InputFields/EmailField/EmailField';
import PhoneNumberField from '../../../Shared/InputFields/PhoneNumberField/PhoneNumberField';
import { useGetFacilityContacts } from '../../Modules/Customers/Components/Facilities/Facilities.api';
import { useParams } from 'react-router-dom';
import Container from '../../../Shared/Container/Container';

interface ISystemUserListProps {
  label?: string;
  users: ICoreSystemUser[];
  save: (systemUsers: ICoreSystemUser[]) => Promise<void>;
  send?: () => Promise<void>;
  isLoading: boolean;
  isUpdateLoading: boolean;
  disableEdit?: boolean;
}

export interface ICoreSystemUser {
  id?: number;
  name: string;
  email?: string;
  phoneNumber?: string;
  mobilePhoneNumber?: string;
  registrationStatus: CoreSystemRegistrationStatus;
  code?: string;
  isResponsible?: boolean;
  hasCode: boolean;
}

export default function SystemUserList(props: ISystemUserListProps) {
  const { facilityId } = useParams<{ facilityId: string }>();
  const { getFacilityContacts } = useGetFacilityContacts();
  const [isEditing, setIsEditing] = useState(false);
  const [users, setUsers] = useState<ICoreSystemUser[]>(props.users);
  const [name, updateName, setName] = useInputState<string>('');
  const [email, updateEmail, setEmail] = useInputState<string>('');
  const [phoneNumber, updatePhoneNumber, setPhoneNumber] = useInputState<string>('');
  const [mobilePhoneNumber, updateMobilePhoneNumber, setMobilePhoneNumber] = useInputState<string>('');
  const [code, updateCode, setCode] = useInputState<string>('');

  const isEmpty = !isEditing && users?.length < 1;
  const isAddValid = name;
  const isUpdateValid = useMemo(() => {
    if (users.length < 1) return true;
    const tempUser = users.find((x) => x.name.length < 1);
    return tempUser ? false : true;
  }, [users]);

  useEffect(() => {
    setUsers(props.users);
  }, [props.users]);

  function toggleIsEditing() {
    setIsEditing(!isEditing);
  }

  async function importContacts() {
    var contacts = await getFacilityContacts(facilityId);

    if (contacts) {
      var newUserList: ICoreSystemUser[] = contacts.map((x) => {
        return {
          name: x.name,
          email: x.email,
          phoneNumber: x.phoneNumber,
          mobilePhoneNumber: x.mobilePhoneNumber ?? x.phoneNumber,
          hasCode: false,
          registrationStatus: CoreSystemRegistrationStatus.new,
        };
      });

      setUsers([...users, ...newUserList.filter((x) => !users.some((y) => y.email === x.email))]);
    }
  }

  function cancel() {
    toggleIsEditing();
    setUsers(props.users);
  }

  function add() {
    setUsers([
      ...users,
      {
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        mobilePhoneNumber: mobilePhoneNumber,
        registrationStatus: CoreSystemRegistrationStatus.new,
        code: code,
        hasCode: !!code,
      } as ICoreSystemUser,
    ]);

    setName('');
    setEmail('');
    setPhoneNumber('');
    setMobilePhoneNumber('');
    setCode('');
  }

  function remove(index: number) {
    setUsers([...users.filter((_, i) => i !== index)]);
  }

  async function save() {
    if (isAddValid)
      await props.save([
        ...users,
        {
          name: name,
          email: email,
          phoneNumber: phoneNumber,
          mobilePhoneNumber: mobilePhoneNumber,
          registrationStatus: CoreSystemRegistrationStatus.new,
          code: code,
          hasCode: !!code,
        } as ICoreSystemUser,
      ]);
    else await props.save(users);
    toggleIsEditing();

    setName('');
    setEmail('');
    setPhoneNumber('');
    setMobilePhoneNumber('');
    setCode('');
  }

  function updateUserRow(
    index: number,
    name: string,
    email?: string,
    phoneNumber?: string,
    mobilePhoneNumber?: string,
    code?: string,
  ) {
    const newList = [...users];
    newList[index] = {
      ...users[index],
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      mobilePhoneNumber: mobilePhoneNumber,
      registrationStatus: CoreSystemRegistrationStatus.updated,
      code: code,
      hasCode: !!code,
    };

    setUsers(newList);
  }

  return (
    <Container
      customSize={{fullsize: true}}
      form
      actions={
        <>
          {props.send != null &&
            <Button variant="contained" color="primary" onClick={props.send} loading={props.isUpdateLoading}>
              Skicka till larmcentral
            </Button>
          }
          {!isEditing && (
            <Tooltip title={props.disableEdit ? 'Larmcentralen har inte bekräftat de senaste ändringarna än.' : ''}>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={props.disableEdit}
                  onClick={toggleIsEditing}
                  style={props.disableEdit ? { pointerEvents: 'none' } : {}}
                >
                  Redigera användarlista
                </Button>
              </span>
            </Tooltip>
          )}
          {isEditing && (
            <>
              <Button variant="outlined" onClick={cancel} disabled={props.isUpdateLoading}>
                Avbryt
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={save}
                disabled={!isUpdateValid}
                loading={props.isUpdateLoading}
              >
                Spara
              </Button>
            </>
          )}
        </>
      }
    >
      <Table
        label={props.label}
        loading={{ loading: props.isLoading, skeletonRows: users?.length ?? 5 }}
        customSize={{
          fullsize: true,
        }}
        empty={{ empty: isEmpty, message: 'Användarlistan är tom' }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Namn</TableCell>
            <TableCell>E-post</TableCell>
            <TableCell>Telefon</TableCell>
            <TableCell>Mobil</TableCell>
            <TableCell>Återkallningskod</TableCell>
            {!isEditing && <TableCell>Status</TableCell>}
            {isEditing && (
              <TableCell>
                <Tooltip title={'Importera kontakter'}>
                  <IconButton
                    disabled={props.disableEdit}
                    onClick={importContacts}
                    style={props.disableEdit ? { pointerEvents: 'none' } : {}}
                  >
                    <RecentActorsRounded />
                  </IconButton>
                </Tooltip>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {users &&
            users
              .sort((x, y) => (x.isResponsible === y.isResponsible ? 0 : x.isResponsible ? -1 : 1))
              .map((x, index) => (
                <SystemUserRow
                  isEditing={isEditing}
                  user={x}
                  key={index}
                  delete={() => remove(index)}
                  update={(name: string, email?: string, phone?: string, mobile?: string, code?: string) => {
                    updateUserRow(index, name, email, phone, mobile, code);
                  }}
                />
              ))}
          {isEditing && (
            <TableRow>
              <TableCell>
                <InputField fullwidth placeholder="Namn" value={name} onChange={updateName} />
              </TableCell>
              <TableCell>
                <EmailField
                  fullwidth
                  removeHelperText
                  placeholder="E-post"
                  value={email}
                  onChange={updateEmail}
                  disableErrorText
                />
              </TableCell>
              <TableCell>
                <PhoneNumberField
                  fullwidth
                  placeholder="Telefon"
                  value={phoneNumber}
                  onChange={updatePhoneNumber}
                  disableErrorText
                />
              </TableCell>
              <TableCell>
                <PhoneNumberField
                  fullwidth
                  placeholder="Mobil"
                  value={mobilePhoneNumber}
                  onChange={updateMobilePhoneNumber}
                  disableErrorText
                />
              </TableCell>
              <TableCell>
                <InputField fullwidth placeholder="Återkallningskod" value={code} onChange={updateCode} />
              </TableCell>
              <TableCell>
                <IconButton color="primary" onClick={add} disabled={!isAddValid} aria-label="Lägg till rad">
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Container>
  );
}

interface ISystemUserRowProps {
  isEditing: boolean;
  user: ICoreSystemUser;
  delete: () => void;
  update: (name: string, email?: string, phone?: string, mobile?: string, code?: string) => void;
}

const SystemUserRow = (props: ISystemUserRowProps) => {
  function updateName(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    props.update(
      e.target.value,
      props.user.email,
      props.user.phoneNumber,
      props.user.mobilePhoneNumber,
      props.user.code,
    );
  }

  function updateEmail(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    props.update(
      props.user.name,
      e.target.value,
      props.user.phoneNumber,
      props.user.mobilePhoneNumber,
      props.user.code,
    );
  }

  function updatePhoneNumber(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    props.update(props.user.name, props.user.email, e.target.value, props.user.mobilePhoneNumber, props.user.code);
  }

  function updateMobilePhoneNumber(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    props.update(props.user.name, props.user.email, props.user.phoneNumber, e.target.value, props.user.code);
  }

  function updateCode(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    props.update(
      props.user.name,
      props.user.email,
      props.user.phoneNumber,
      props.user.mobilePhoneNumber,
      e.target.value,
    );
  }

  return (
    <TableRow>
      <TableCell>
        {props.user.isResponsible && <>*</>}
        {props.isEditing ? (
          <InputField fullwidth placeholder="Namn" value={props.user.name} onChange={updateName} />
        ) : (
          props.user.name
        )}
      </TableCell>
      <TableCell>
        {props.isEditing ? (
          <EmailField fullwidth removeHelperText placeholder="E-post" value={props.user.email} onChange={updateEmail} />
        ) : (
          props.user.email
        )}
      </TableCell>
      <TableCell>
        {props.isEditing ? (
          <PhoneNumberField
            fullwidth
            placeholder="Telefon"
            value={props.user.phoneNumber}
            onChange={updatePhoneNumber}
          />
        ) : (
          props.user.phoneNumber
        )}
      </TableCell>
      <TableCell>
        {props.isEditing ? (
          <PhoneNumberField
            fullwidth
            placeholder="Mobil"
            value={props.user.mobilePhoneNumber}
            onChange={updateMobilePhoneNumber}
          />
        ) : (
          props.user.mobilePhoneNumber
        )}
      </TableCell>
      <TableCell>
        {props.isEditing ? (
          <InputField
            fullwidth
            placeholder={props.user.hasCode ? 'Ny återkallningskod' : 'Återkallningskod'}
            value={props.user.hasCode && !props.isEditing ? '****' : props.user.code}
            onChange={updateCode}
          />
        ) : props.user.hasCode && !props.isEditing ? (
          '****'
        ) : (
          props.user.code
        )}
      </TableCell>
      {!props.isEditing && (
        <TableCell>
          {CoreSystemRegistrationStatusName.find((x) => x.status === props.user.registrationStatus)?.name}
        </TableCell>
      )}
      {props.isEditing && (
        <TableCell>
          {!props.user.isResponsible && (
            <IconButton color="error" onClick={props.delete} aria-label="Ta bort rad">
              <RemoveIcon />
            </IconButton>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};
