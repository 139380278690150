import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import DialogForm from '../../../../../Shared/DialogForm/DialogForm';
import { DialogContentHeader } from '../../../../../Shared/DialogContentHeader/DialogContentHeader';
import Button from '../../../../../Shared/Button/Button';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import { ISelectOption, Select } from '../../../../../Shared/Select';
import useFormValidation from '../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import useInputState from '../../../../../Shared/Hooks/UseInputState/UseInputState';
import { isResultError } from '../../../../../Shared/Api/response/ICreateResult';
import CustomerFacilitySelect from '../../../Customers/Components/CustomerFacilitySelect/CustomerFacilitySelect';
import { useGetContactList } from '../../../Customers/Customers.api';
import { IUpsertServiceCommandValidationErrors, ServiceDto, useUpsertService } from '../../Forum.api';
import PhoneNumberField from '../../../../../Shared/InputFields/PhoneNumberField/PhoneNumberField';
import EmailField from '../../../../../Shared/InputFields/EmailField/EmailField';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';
import { useGetFacility } from '../../../Customers/Components/Facilities/Facilities.api';

const newCustomerContact = { value: '0', label: 'Ny kontakt' };
export interface IServiceFormProps {
  title: string;
  service?: ServiceDto;
  open: boolean;
  setOpen: (open: boolean) => void;
  onUpsert: (id: number) => void;
}

const ServiceForm: React.FunctionComponent<IServiceFormProps> = (props: IServiceFormProps) => {
  const { title, service, open, setOpen, onUpsert } = props;

  const [selectedCustomerId, setSelectedCustomerId] = useState<string | undefined>();
  const [selectedFacilityId, setSelectedFacilityId] = useState<string | undefined>();
  const [selectedCoreSystemId, setSelectedCoreSystemId] = useState<string | undefined>();
  const [selectedContactId, setSelectedContactId] = useState<string | undefined>(
    service?.customerContactId?.toString(),
  );
  const [contactOptions, setContactOptions] = useState<ISelectOption[]>([]);
  const [contactFirstName, changeContactFirstName, setContactFirstName] = useInputState('');
  const [contactLastName, changeContactLastName, setContactLastName] = useInputState('');
  const [contactEmail, changeContactEmail, setContactEmail] = useInputState('');
  const [contactPhoneNumber, changeContactPhoneNumber, setContactPhoneNumber] = useInputState('');
  const [contactPosition, changeContactPosition, setContactPosition] = useInputState('');
  const [jobDescription, changeJobDescription, setJobDescription] = useInputState(service?.description ?? '');
  const [errors, setErrors] = useState<IUpsertServiceCommandValidationErrors>();
  const { getFacility, isLoading: isGetFacilityLoading } = useGetFacility(selectedCustomerId);
  const [coreSystemOptions, setCoreSystemOptions] = useState<ISelectOption[]>([]);

  const { isFormValid, formRef } = useFormValidation();
  const { upsert, isUpsertLoading } = useUpsertService();
  const { getContactList, isLoading: contactsLoading } = useGetContactList(selectedCustomerId);

  const titleWithoutSpaces = title.replace(/\s/g, '');
  const isValid = isFormValid && selectedCustomerId && selectedFacilityId;
  const isNewContact = selectedContactId === '0';


  useEffect(() => {
    async function loadCoreSystems() {
      const response = await getFacility(selectedFacilityId);
      if (response) {
        var options = response.coreSystems.map((x) => ({ value: x.id.toString(), label: x.description }));
        setCoreSystemOptions(options);
        if (options.length === 1) {
          setSelectedCoreSystemId(options[0].value);
        }
      }
    }
    open && selectedFacilityId != null && loadCoreSystems();
  }, [getFacility, open, selectedFacilityId]);

  useEffect(() => {
    if (service !== undefined) {
      setSelectedCustomerId(service.customerId.toString());
      setSelectedFacilityId(service.facilityId.toString());
      setSelectedCoreSystemId(service.coreSystemId?.toString());
      setSelectedContactId(service.customerContactId?.toString());
      setJobDescription(service.description);
    }
  }, [service, setJobDescription]);

  useEffect(() => {
    async function loadContactList() {
      if (selectedCustomerId) {
        var response = await getContactList();
        setContactOptions([
          newCustomerContact,
          ...response.map((x) => ({ value: x.id.toString(), label: x.firstname + ' ' + x.lastname })),
        ]);
      } else {
        setContactOptions([]);
      }
    }
    open && loadContactList();
  }, [open, selectedCustomerId, getContactList]);

  async function handleUpsert() {
    const result = await upsert(
      {
        customerId: Number(selectedCustomerId),
        facilityId: Number(selectedFacilityId),
        coreSystemId: Number(selectedCoreSystemId),
        customerContactId: selectedContactId && !isNewContact ? Number(selectedContactId) : undefined,
        contactPerson: isNewContact
          ? {
              firstName: contactFirstName,
              lastName: contactLastName,
              email: contactEmail,
              phoneNumber: contactPhoneNumber,
              position: contactPosition,
            }
          : undefined,
        description: jobDescription,
      },
      service?.id,
    );

    if (isResultError(result)) {
      setErrors(result);
    } else {
      onUpsert(Number(result));
      handleCancel();
    }
  }

  const handleResetContact = () => {
    setContactFirstName('');
    setContactLastName('');
    setContactEmail('');
    setContactPhoneNumber('');
    setContactPosition('');
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedCustomerId(undefined);
    setSelectedFacilityId(undefined);
    setSelectedCoreSystemId(undefined);
    setSelectedContactId(undefined);
    setJobDescription('');
    handleResetContact();
  };

  return (
    <Dialog open={open} onClose={props.setOpen} aria-labelledby={titleWithoutSpaces}>
      <DialogContent>
        <DialogContentHeader onClose={() => handleCancel()} title={title} titleId={titleWithoutSpaces} />

        <DialogForm
          ref={formRef}
          actions={
            <>
              <Button color="default" variant="outlined" onClick={handleCancel}>
                Avbryt
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleUpsert}
                loading={isUpsertLoading}
                disabled={!isValid}
              >
                {service === undefined ? 'Skapa' : 'Spara'}
              </Button>
            </>
          }
        >
          <CustomerFacilitySelect
            required
            requireSystem
            fullWidth
            customerId={selectedCustomerId ? parseInt(selectedCustomerId) : undefined}
            facilityId={selectedFacilityId ? parseInt(selectedFacilityId) : undefined}
            onChange={(customer) => {
              setSelectedCustomerId(customer ? customer.id.toString() : undefined);
              setSelectedFacilityId(customer ? customer.facilityId.toString() : undefined);
            }}
          />        
          <Select
            inputFieldProps={{ label: 'System', required: false, fullWidth: true}}            
            selectProps={{
              isClearable: true,
              value: coreSystemOptions.find((x) => x.value === selectedCoreSystemId?.toString()) || null,
              isLoading: isGetFacilityLoading,
              isDisabled: !coreSystemOptions.length,
              options: coreSystemOptions,
              onChange: (selected) => setSelectedCoreSystemId(selected ? (selected as ISelectOption).value : undefined),
            }}
          />
          <Select
            inputFieldProps={{ label: 'Välj kontakt' }}
            selectProps={{
              value: (selectedContactId && contactOptions?.find((x) => x.value === selectedContactId)) || null,
              isDisabled: !!(contactOptions?.length === 0),
              isLoading: contactsLoading,
              onChange: (selected) => {
                var value = selected ? (selected as ISelectOption).value : undefined;
                setSelectedContactId(value);
                if (value && value !== '0') handleResetContact();
              },
              options: contactOptions,
            }}
          />
          {isNewContact && (
            <Fieldset legend="Servicemottagare">
              <InputField
                label="Förnamn"
                type="text"
                required={isNewContact}
                value={contactFirstName}
                onChange={changeContactFirstName}
              />
              <InputField
                label="Efternamn"
                type="text"
                required={isNewContact}
                value={contactLastName}
                onChange={changeContactLastName}
              />
              <EmailField
                label="E-post"
                type="text"
                required={isNewContact}
                value={contactEmail}
                onChange={changeContactEmail}
              />
              <PhoneNumberField
                label="Telefon"
                required={isNewContact}
                value={contactPhoneNumber}
                onChange={changeContactPhoneNumber}
              />
              <InputField
                label="Befattning/Avdelning"
                type="text"
                value={contactPosition}
                onChange={changeContactPosition}
              />
            </Fieldset>
          )}
          <InputField
            label="Beskrivning"
            type="text"
            value={jobDescription}
            onChange={changeJobDescription}
            multiline
            rows={5}
            required
            fullwidth
            errorText={errors?.description}
          />
        </DialogForm>
      </DialogContent>
    </Dialog>
  );
};

export default ServiceForm;
