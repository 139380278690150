import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { IUpsertFacilityCommand, useGetFacility, useGetFacilityContacts, useUpsertFacility } from '../Facilities.api';
import Container from '../../../../../../Shared/Container/Container';
import Button from '../../../../../../Shared/Button/Button';
import AccessGuard from '../../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../../Core/Authentication/ModuleAccess';
import CoreSystemTable from './CoreSystemTable/CoreSystemTable';
import { ViewAddress } from '../../../../../../Shared/InputFields/Address/ViewAddress';
import { FacilityChangeCustomerButton } from '../FacilityChangeCustomerButton/FacilityChangeCustomerButton';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import { Fieldset } from '../../../../../../Shared/Form/Fieldset/Fieldset';
import { Tab, useMediaQuery, useTheme } from '@material-ui/core';
import Tabs from '../../../../../../Shared/Tabs/Tabs';
import ContactList from '../../Contacts/ContactList/ContactList';
import { SaleList } from '../../ViewCustomer/SaleList/SaleList';
import AgreementList from '../../ViewCustomer/AgreementList/AgreementList';
import DocumentList from '../../ViewCustomer/Documents/Components/DocumentList/DocumentList';
import { useGetCustomer } from '../../../Customers.api';
import useInputState from '../../../../../../Shared/Hooks/UseInputState/UseInputState';
import Snackbar from '../../../../../../Shared/Hooks/UseErrorMessage/Snackbar/Snackbar';
import { isResultError } from '../../../../../../Shared/Api/response/ICreateResult';
import SystemLogList from '../../../../Admin/Components/SystemLog/SystemLogList/SystemLogList';
import { SystemLogType } from '../../../../Admin/Components/SystemLog/SystemLog.api';
import PopperMenu from '../../../../../../Shared/Popper/Popper';
import OrderForm from '../../../../Errands/Components/Order/OrderForm/OrderForm';

const ViewFacility = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { hash } = useLocation();
  const { customerId, id } = useParams<{ customerId: string; id: string }>();
  const { isLoading: isFacilityLoading, facility, getFacility } = useGetFacility(customerId);
  console.log(facility);
  const { isLoading: isFacilityContactLoading, getFacilityContacts } = useGetFacilityContacts();
  const { isLoading: isUpsertLoading, upsert } = useUpsertFacility(customerId);
  const { isLoading: isCustomerLoading, customer, getCustomer } = useGetCustomer();
  const [avtalsfakturaNumber, changeAvtalsfakturaNumber] = useState<string>('');
  const [facilityNote, changeFacilityNote, setFacilityNote] = useInputState<string>('');
  const [openSuccessNoteText, setSuccessNoteText]= useState<boolean>(false);
  const [openOrderPopup, setOrderPopup]= useState<boolean>(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const tabList = [
    "kontaktinformation",
    "ordrar",
    "avtal",
    "dokument",
    "handelser",
  ]

  const handleGetCustomer = useCallback(async () => {
    const response = await getCustomer(`${customerId}/${false}`);

    if (!response) {
      history.push('/customers/' + customerId);
    }
  }, [customerId, history, getCustomer]);

  const handleGetFacility = useCallback(async () => {
    const response = await getFacility(id);
    setFacilityNote(response?.internalNote ?? '');

    if (!response) {
      history.push('/customers/' + customerId);
    }
  }, [customerId, history, id, getFacility, setFacilityNote]);

  const handleGetFacilityContacts = useCallback(async () => {
    const response = await getFacilityContacts(id);

    if (!response) {
      history.push('/customers/' + customerId);
    }
  }, [customerId, history, id, getFacilityContacts]);

  function setOpenPopup() {
    setOrderPopup(!openOrderPopup);
  }

  useEffect(() => {
    handleGetCustomer();
    handleGetFacility();
    handleGetFacilityContacts();
  }, [handleGetCustomer, handleGetFacility, handleGetFacilityContacts]);

  const onCustomerChange = () => history.push('/customers/' + customerId);

  useEffect(() => {
    if (facility?.coreSystems && facility?.coreSystems?.length > 0) {
      let avtalsfakturanr = '';
      facility.coreSystems.forEach((f) => {
        f.agreements.forEach((a) => {
          if ((a.isActive || (a.startDate && new Date(a.startDate) > new Date())) && a.externalAgreementInvoiceNumber != null) {
            avtalsfakturanr +=
              avtalsfakturanr.length > 0 ? ', ' + a.externalAgreementInvoiceNumber : a.externalAgreementInvoiceNumber;
          }
        });
      });
      changeAvtalsfakturaNumber(avtalsfakturanr ?? '-');
    } else {
      changeAvtalsfakturaNumber('-');
    }
  }, [facility]);

  async function upsertFacility() {
    if (facility) {
      const upsertCommand: IUpsertFacilityCommand = {
        name: facility.name,
        address: facility.address,
        postalCode: facility.postalCode,
        city: facility.city,
        invoiceAddress: facility.invoiceAddress,
        invoicePostalCode: facility.invoicePostalCode,
        invoiceCity: facility.invoiceCity,
        invoiceEmail: facility.invoiceEmail,
        invoiceNote: facility.invoiceNote,
        internalNote: facilityNote
      };
  
      const result = await upsert(upsertCommand, id);
      if (!isResultError(result)) {
        setSuccessNoteText(true);
        setInterval(() => {
          setSuccessNoteText(false);
        }, 3000)
      }
    }    
  }

  return (
    <>
      <Container
        form
        loading={isFacilityContactLoading || isFacilityLoading || isCustomerLoading}
        label={facility?.name}
        actions={
          <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
            <FacilityChangeCustomerButton
              onUpdateCustomer={onCustomerChange}
              currentCustomerId={customerId}
              facilityId={id}
            />
            <Button
              color="default"
              variant="outlined"
              onClick={() => history.push(`${history.location.pathname}/update`)}
            >
              Redigera
            </Button>
            <Button
              color="default"
              variant="outlined"
              onClick={setOpenPopup}
              disabled={facility?.coreSystems.length === 0}
              title={facility?.coreSystems.length === 0 ? "Platsen saknar system." : undefined}
            >
              Skapa serviceorder
            </Button>
          </AccessGuard>
        }
        customSize={{ lg: 6, md: 6 }}
      >
        <Fieldset legend="Huvudkundens uppgifter" customSize={{ lg: 12, md: 12 }} contentAlignment="flex-start">
          <InputField customWidth={4} label="Namn" value={customer?.name} viewOnly />
          <InputField customWidth={4} label="Org-/Pers.nr" value={customer?.identificationNumber} viewOnly />
          <InputField customWidth={4} label="Telefon" value={customer?.customerPhoneNumber} viewOnly />
          <ViewAddress
            address={customer?.customerAddress}
            postalCode={customer?.customerPostalCode}
            city={customer?.customerCity}
            customWidth={4}
          />
        </Fieldset>
        <Fieldset legend="Platsuppgifter">
          <InputField label={'Platsnamn'} value={facility?.name} viewOnly customWidth={4} />
          <InputField label={'Kundnummer'} value={facility?.facilityNumber} viewOnly customWidth={4} />
          <ViewAddress
            address={facility?.address}
            postalCode={facility?.postalCode}
            city={facility?.city}
            customWidth={4}
          />
          <InputField label={'E-faktura'} value={customer?.invoiceEmail} viewOnly customWidth={4} />
          <InputField label={'Avtalsfakturanr'} value={avtalsfakturaNumber} viewOnly customWidth={4} />
          <ViewAddress
            customLabel="Fakturaadress"
            address={facility?.invoiceAddress}
            postalCode={facility?.invoicePostalCode}
            city={facility?.invoiceCity}
            customWidth={4}
          />
          <InputField label={'E-faktura anteckning'} value={facility?.invoiceNote} viewOnly customWidth={4} />
        </Fieldset>
      </Container>
      <Container customSize={{
            lg: 6,
            md: 6,
          }} noPadding>
        <Tabs customSize={{
            lg: 12
          }}
          startIndex={hash && hash.length > 0  ? tabList.findIndex((x) => x === hash.slice(1)) : 0}
          onChange={(index) => { window.location.hash = tabList[index]}}
        >
          <Tab label="Kontakter">
            <ContactList customerId={customerId} facilityId={id} />
            {/* <Snackbar
              message={'Ny inbjudan har skickats.'}
              open={openConfirmText}
              severity={'success'}
              onClose={() => setOpenConfirmText(false)}
            /> */}
          </Tab>
          <Tab label="Ordrar">
            <SaleList customerId={customerId} facilityId={id} />
          </Tab>
          <Tab label="Avtal">
            <AgreementList customerId={customerId} facilityId={id} />
          </Tab>
          <Tab label="Dokument">
            <DocumentList customerName={facility?.name} customerId={customerId} facilityId={id} />
          </Tab>
          <Tab label="Händelser">
          {facility && (
            <SystemLogList
              customSize={{ fullsize: true }}
              defaultEntityId={parseInt(id)}
              lockCustomerId
              defaultLogType={SystemLogType.Facility}
              lockLogType
              hideLabel
              customPage={[10, 15, 20]}
            />
          )}
        </Tab>
        </Tabs>
        <div style={{position: 'relative', padding: 0}}>
          <InputField label={'Anteckningar'} value={facilityNote} customWidth={12} multiline rows={6} onChange={changeFacilityNote} style={{padding: '0.25rem 0'}}/>
          {facilityNote !== '' && facilityNote !== facility?.internalNote && (
            <Button
              color="primary"
              variant="contained"
              style={!mobileView ? {position: 'absolute', right: '1rem', bottom: '1rem'} : {}}
              disabled={isUpsertLoading}
              onClick={upsertFacility}
            >
              Spara
            </Button>)}
            <Snackbar
              message={'Anteckningar sparades.'}
              open={openSuccessNoteText}
              severity={'success'}
              onClose={() => setSuccessNoteText(false)}
            />
        </div>
      </Container>
      <CoreSystemTable
        coreSystemsList={handleGetFacility}
        coreSystems={facility?.coreSystems}
        loading={isFacilityLoading}
      />
      {openOrderPopup && (
        <PopperMenu
          setOpen={setOpenPopup}
          open={openOrderPopup}
          onClose={() => setOpenPopup()}
          buttonType="icon"
          customContent
          alwaysDialog
          title={'Lägg till serviceorder'}
          content={
            <OrderForm location={`errands/orders`} fullwidth customerId={customer?.id.toString()} facilityId={facility?.id.toString()}/>
          }
        />
      )}
    </>
  );
};

export default ViewFacility;
