import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { InputAdornment, Checkbox, FormControlLabel, Radio } from '@material-ui/core';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import Container from '../../../../../../../Shared/Container/Container';
import Select, { ISelectOption } from '../../../../../../../Shared/Select/Select';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import CheckboxField from '../../../../../../../Shared/InputFields/CheckboxField/CheckboxField';
import RadioField from '../../../../../../../Shared/InputFields/RadioField/RadioField';
import Datepicker from '../../../../../../../Shared/InputFields/DatePicker/Datepicker';
import WizardFormActions from '../../../../../../../Shared/Form/WizardFormActions/WizardFormActions';
import useFormValidation from '../../../../../../../Shared/Hooks/UseFormValidation/useFormValidation';
import useNullableNumericInputState from '../../../../../../../Shared/Hooks/UseNullableNumericInputState/UseNullableNumericInputState';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import { SaleType } from '../../../../../../Shared/SaleType';
import { getSelectedOption, getSelectedValue } from '../../../../../../Shared/utils';
import { useGetSuppliers, SupplierType } from '../../../../../Admin/Components/Suppliers/Suppliers.api';
import OfferFormContext from '../OfferFormContext';
import {
  measures,
  services,
  auditIntervals,
  rentTypes,
  transferTypes,
  transfersIntervals,
  Measure,
  Service,
  AuditInterval,
  RentType,
  TransferType,
  TransferInterval,
  mapAgreementOptions,
  invoiceIntervals,
  InvoiceInterval,
} from '../../../Sale.api';
import {
  getNextPage,
  getPreviousPage,
  IUpdateOfferAgreementCommand,
  useGetOfferAgreement,
  useUpdateOfferAgreement,
} from '../../Offer.api';
import moment from 'moment';

const measureOptions: ISelectOption[] = mapAgreementOptions(measures);
const serviceOptions: ISelectOption[] = mapAgreementOptions(services);
const auditOptions: ISelectOption[] = mapAgreementOptions(auditIntervals);
const rentOptions: ISelectOption[] = mapAgreementOptions(rentTypes);
const transferOptions: ISelectOption[] = mapAgreementOptions(transferTypes);
const transferIntervalOptions: ISelectOption[] = mapAgreementOptions(transfersIntervals);
const invoiceOptions: ISelectOption[] = mapAgreementOptions(invoiceIntervals);


const getEndDateFromYears = (years: number = 0) => {
  let tempEndDate = new Date();
  tempEndDate.setFullYear(tempEndDate?.getFullYear() + years);
  return tempEndDate;
};

const validateMonthlyCost = (monthlyCost: number | null) => {
  return monthlyCost != null && monthlyCost >= 0;
};

const SetAgreement: React.FunctionComponent = () => {
  const { id: offerId } = useParams<{ id: string }>();
  const { offerStep, setOfferStep, offerType } = useContext(OfferFormContext);
  const { isFormValid, formRef } = useFormValidation();

  const { get: getOfferAgreement, isLoading: isGetOfferAgreementLoading } = useGetOfferAgreement(offerId);
  const { isLoading: isGetSuppliersLoading, getSuppliers } = useGetSuppliers();
  const { put: updateOfferAgreement, isLoading: isUpdateOfferAgreementLoading } = useUpdateOfferAgreement(offerId);

  const [useAgreement, setUseAgreement] = useState<boolean>(offerType === SaleType.deal);
  const [securityCompanyOptions, setSecurityCompanyOptions] = useState<ISelectOption[]>([]);
  const [callCentreOptions, setCallCentreOptions] = useState<ISelectOption[]>([]);

  const [monthlyCost, changeMonthlyCost, setMonthlyCost] = useNullableNumericInputState(null);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(getEndDateFromYears(3));
  const [callCentreCompany, setCallCentreCompany] = useState<number | undefined>(undefined);
  const [securityCompany, setSecurityCompany] = useState<number | undefined>(undefined);
  const [measure, setMeasure] = useState<Measure | undefined>(undefined);
  const [service, setService] = useState<Service | undefined>(undefined);
  const [auditInterval, setAuditInterval] = useState<AuditInterval | undefined>(undefined);
  const [invoiceInterval, setInvoiceInterval] = useState<InvoiceInterval | undefined>(InvoiceInterval.ThreeMonths);
  const [transferType, setTransferType] = useState<TransferType | undefined>(undefined);
  const [secondaryTransferType, setSecondaryTransferType] = useState<TransferType | undefined>(undefined);
  const [transferInterval, setTransferInterval] = useState<TransferInterval | undefined>(undefined);
  const [rentType, setRentType] = useState<RentType | undefined>(undefined);
  const [includeInnerControl, setIncludeInnerControl] = useState<boolean>(false);

  const valid: boolean = !!(!useAgreement || (isFormValid && endDate > startDate && validateMonthlyCost(monthlyCost)));

  useEffect(() => {
    if (offerType === SaleType.deal) setUseAgreement(true);
  }, [offerType]);

  useEffect(() => {
    async function loadOptions() {
      const emergencyCallCenters = await getSuppliers({
        supplierType: SupplierType.EmergencyCallCenter,
        pageNumber: 1,
        pageSize: 100,
      });
      if (emergencyCallCenters) {
        const tempEmergencyCallOptions = emergencyCallCenters.items.map((x) => ({
          label: x.name,
          value: x.id.toString(),
        }));
        setCallCentreOptions(tempEmergencyCallOptions);
      }

      const securityCompanies = await getSuppliers({
        supplierType: SupplierType.SecurityCompany,
        pageNumber: 1,
        pageSize: 100,
      });

      if (securityCompanies) {
        const tempSecurityCompaniesOptions = securityCompanies.items.map((x) => ({
          label: x.name,
          value: x.id.toString(),
        }));
        setSecurityCompanyOptions(tempSecurityCompaniesOptions);
      }
    }
    loadOptions();
  }, [getSuppliers]);

  /* Set up offer agreement data */
  useEffect(() => {
    async function loadOfferAgreementData() {
      const response = await getOfferAgreement();

      if (response) {
        setUseAgreement((x) => (response.offerAgreement ? true : x));
        setMonthlyCost(response.offerAgreement?.monthlyCost ?? 0);
        setStartDate(response.offerAgreement?.startDate ? new Date(response.offerAgreement?.startDate) : new Date());
        setEndDate((x) => (response.offerAgreement?.endDate ? new Date(response.offerAgreement?.endDate) : x));
        setCallCentreCompany(response.offerAgreement?.emergencyCallCenterSupplierId);
        setSecurityCompany(response.offerAgreement?.securityCompanySupplierId);
        setMeasure(response.offerAgreement?.measure);
        setService(response.offerAgreement?.service);
        setAuditInterval(response.offerAgreement?.auditInterval);
        setInvoiceInterval(response.offerAgreement?.invoiceInterval ?? InvoiceInterval.ThreeMonths);
        setTransferType(response.offerAgreement?.transferType);
        setSecondaryTransferType(response.offerAgreement?.secondaryTransferType);
        setTransferInterval(response.offerAgreement?.transferInterval);
        setRentType(response.offerAgreement?.rentType);
        setIncludeInnerControl(response.offerAgreement?.includeInnerControl ?? false);
      }
    }
    if (!!offerId) loadOfferAgreementData();
  }, [offerId, getOfferAgreement, setMonthlyCost]);

  /* Insert/update offer agreement data */
  const upsertOfferAgreement = async () => {
    if (offerType === undefined || (useAgreement && !startDate)) {
      return;
    }

    const updateOfferAgreementCommand: IUpdateOfferAgreementCommand = {
      addAgreement: useAgreement,
      agreement: useAgreement
        ? {
            monthlyCost: monthlyCost,
            startDate: startDate!,
            endDate: endDate,
            emergencyCallCenterSupplierId: callCentreCompany,
            securityCompanySupplierId: securityCompany,
            measure: measure,
            service: service,
            auditInterval: auditInterval,
            invoiceInterval: invoiceInterval,
            rentType: rentType,
            transferType: transferType,
            secondaryTransferType: secondaryTransferType,
            transferInterval: transferInterval,
            includeInnerControl: includeInnerControl,
          }
        : undefined,
    };

    const result = await updateOfferAgreement(updateOfferAgreementCommand);

    if (!isResultError(result)) {
      setOfferStep(getNextPage(offerType, offerStep));
    } else {
      //?? TODO: How to handle result error?
    }
  };

  return (
    <Container
      form
      ref={formRef}
      label="Avtal"
      loading={isGetOfferAgreementLoading}
      actions={
        <WizardFormActions
          goBack={() => setOfferStep(getPreviousPage(offerType!, offerStep))}
          goNext={upsertOfferAgreement}
          isFormValid={valid && offerType !== undefined}
          isProcessing={isUpdateOfferAgreementLoading}
        />
      }
    >
      {offerType !== SaleType.deal && (
        <RadioField label="Använd avtal">
          <FormControlLabel
            label="Ja"
            control={<Radio color="primary" />}
            checked={useAgreement}
            onChange={(_, checked) => setUseAgreement(checked)}
          />
          <FormControlLabel
            label="Nej"
            control={<Radio color="primary" />}
            checked={!useAgreement}
            onChange={(_, checked) => setUseAgreement(!checked)}
          />
        </RadioField>
      )}
      <>
        {useAgreement && (
          <InputField
            autoComplete="off"
            label="Månadsavgift"
            type="text"
            fullwidth
            endAdornment={<InputAdornment position="end">SEK</InputAdornment>}
            required
            value={monthlyCost}
            onChange={changeMonthlyCost}
          />
        )}
      </>
      {useAgreement && (
        <>
          <Fieldset legend="Datum">
            <Datepicker
              label="Från"
              onChange={(x: Date | null) => {
                if (x) setStartDate(x);
              }}
              selected={startDate}
            />
            <Datepicker
              label="Till"
              minDate={startDate as Date}
              inFilter={true}
              onChange={(x: Date | null) => {
                if (x) setEndDate(x);
              }}
              selected={endDate}
              disabled={moment().diff(endDate, 'days') / -365 === 3}
              radioBoxProps={{
                radioBoxLabel: 'Test',
                radioBoxCheckedValue: moment().diff(endDate, 'days') / -365,
                alternatives: [
                  {
                    radioBoxAlternativeLabel: '1 år',
                    radioBoxAlternativeValue: 1,
                  },
                  {
                    radioBoxAlternativeLabel: '2 år',
                    radioBoxAlternativeValue: 2,
                  },
                  {
                    radioBoxAlternativeLabel: 'Tills vidare (3 år)',
                    radioBoxAlternativeValue: 3,
                  },
                ],
                onRadioBoxChange: (value) => {
                  if (value === 3 && auditInterval) {
                    setEndDate(getEndDateFromYears(auditInterval ?? 3));
                  } else {
                    setEndDate(getEndDateFromYears(value));
                  }
                },
              }}
            />
          </Fieldset>
          <Fieldset legend="Larm">
            <Select
              inputFieldProps={{ label: 'Larmcentral' }}
              selectProps={{
                isClearable: true,
                isLoading: isGetSuppliersLoading,
                options: callCentreOptions,
                value: getSelectedOption(callCentreOptions, callCentreCompany),
                onChange: (option) => {
                  setCallCentreCompany(getSelectedValue(option));
                },
              }}
            />
            <Select
              inputFieldProps={{ label: 'Vaktbolag' }}
              selectProps={{
                isClearable: true,
                isLoading: isGetSuppliersLoading,
                options: securityCompanyOptions,
                value: getSelectedOption(securityCompanyOptions, securityCompany),
                onChange: (option) => {
                  setSecurityCompany(getSelectedValue(option));
                },
              }}
            />
            <Select
              inputFieldProps={{ label: 'Åtgärd' }}
              selectProps={{
                isClearable: true,
                options: measureOptions,
                value: getSelectedOption(measureOptions, measure),
                onChange: (option) => {
                  setMeasure(getSelectedValue(option));
                },
              }}
            />
            <Select
              inputFieldProps={{ label: 'Service' }}
              selectProps={{
                isClearable: true,
                options: serviceOptions,
                value: getSelectedOption(serviceOptions, service),
                onChange: (option) => {
                  setService(getSelectedValue(option));
                },
              }}
            />
            <Select
              inputFieldProps={{ label: 'Revision' }}
              selectProps={{
                isClearable: true,
                options: auditOptions,
                value: getSelectedOption(auditOptions, auditInterval),
                onChange: (option) => {
                  setAuditInterval(getSelectedValue(option));
                },
              }}
            />
            <Select
              inputFieldProps={{ label: 'Fakturaintervall' }}
              selectProps={{
                isClearable: false,
                options: invoiceOptions,
                defaultValue: getSelectedOption(invoiceOptions, InvoiceInterval.ThreeMonths),
                value: getSelectedOption(invoiceOptions, invoiceInterval),
                onChange: (option) => {
                  setInvoiceInterval(getSelectedValue(option));
                },
              }}
            />
            <Select
              inputFieldProps={{ label: 'Överföring' }}
              selectProps={{
                isClearable: true,
                options: transferOptions,
                value: getSelectedOption(transferOptions, transferType),
                onChange: (option) => {
                  setTransferType(getSelectedValue(option));
                },
              }}
            />
            <Select
              inputFieldProps={{ label: 'Sekundär överföring' }}
              selectProps={{
                isClearable: true,
                options: transferOptions,
                value: getSelectedOption(transferOptions, secondaryTransferType),
                onChange: (option) => {
                  setSecondaryTransferType(getSelectedValue(option));
                },
              }}
            />
            <Select
              inputFieldProps={{ label: 'Överföringsperiod' }}
              selectProps={{
                isClearable: true,
                options: transferIntervalOptions,
                value: getSelectedOption(transferIntervalOptions, transferInterval),
                onChange: (option) => {
                  setTransferInterval(getSelectedValue(option));
                },
              }}
            />
            <Select
              inputFieldProps={{ label: 'Hyra' }}
              selectProps={{
                isClearable: true,
                options: rentOptions,
                value: getSelectedOption(rentOptions, rentType),
                onChange: (option) => {
                  setRentType(getSelectedValue(option));
                },
              }}
            />
            <CheckboxField label="Nyckelförvaring">
              <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label="Inklusive inre kontroll"
                checked={includeInnerControl}
                onChange={(_, checked) => setIncludeInnerControl(checked)}
              />
            </CheckboxField>
          </Fieldset>
        </>
      )}
    </Container>
  );
};

export default SetAgreement;
