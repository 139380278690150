import React, { useState, useEffect } from 'react';
import useInputState from '../../../Shared/Hooks/UseInputState/UseInputState';
import { InputField } from '../../../Shared/InputFields/InputField/InputField';
import { Table } from '../../../Shared/Table/Table';
import Button from '../../../Shared/Button/Button';
import { TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import IconButton from '../../../Shared/IconButton/IconButton';
import Container from '../../../Shared/Container/Container';

interface IEventActionListProps {
  label?: string;
  eventActions: ICoreSystemEventAction[];
  save: (eventActions: ICoreSystemEventAction[]) => Promise<void>;
  send?: () => Promise<void>;
  isLoading: boolean;
  isUpdateLoading: boolean;
}

export interface ICoreSystemEventAction {
  id?: number;
  eventName: string;
  timespan: string;
  action: string;
}

export default function EventActionList(props: IEventActionListProps) {
  const [eventActions, setEventActions] = useState<ICoreSystemEventAction[]>(props.eventActions);
  const [eventName, updateEventName, setEventName] = useInputState<string>('');
  const [timespan, updateTimespan, setTimespan] = useInputState<string>('');
  const [action, updateAction, setAction] = useInputState<string>('');

  const isEmpty = eventActions?.length < 1;
  const isAddValid = eventName && timespan && action;

  useEffect(() => {
    setEventActions(props.eventActions);
  }, [props.eventActions]);

  function add() {
    setEventActions([
      ...eventActions,
      {
        eventName: eventName,
        timespan: timespan,
        action: action,
      },
    ]);

    setEventName('');
    setTimespan('');
    setAction('');
  }

  function remove(index: number) {
    setEventActions([...eventActions.filter((x, i) => i !== index)]);
  }

  async function save() {
    if (isAddValid) {
      await props.save([
        ...eventActions,
        {
          eventName: eventName,
          timespan: timespan,
          action: action,
        },
      ]);
    } else {
      await props.save(eventActions);
    }

    setEventName('');
    setTimespan('');
    setAction('');
  }

  function updateEventActionRow(index: number, eventName: string, timespan: string, action: string) {
    eventActions[index] = {
      id: eventActions[index].id,
      eventName: eventName,
      timespan: timespan,
      action: action,
    };

    setEventActions([...eventActions]);
  }

  return (
    <Container
      customSize={{fullsize: true}}
      form
      actions={
        <>
          {props.send != null &&
            <Button variant="contained" color="primary" onClick={props.send} loading={props.isUpdateLoading}>
              Skicka till larmcentral
            </Button>
          }
          <Button variant="contained" color="primary" onClick={save} loading={props.isUpdateLoading}>
            Spara
          </Button>
        </>
      }
    >
      <Table
        label={props.label}
        loading={{ loading: props.isLoading, skeletonRows: eventActions?.length ?? 5 }}
        customSize={{
          fullsize: true,
        }}
        empty={{ empty: isEmpty, message: 'Åtgärdslistan är tom' }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Händelse</TableCell>
            <TableCell>Larmtid</TableCell>
            <TableCell>Åtgärd</TableCell>
            <TableCell width={50}>
              <span className={'sr-only'}>Knappar</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventActions?.map((x, index) => (
            <EventActionRow
              isEditing={true}
              eventAction={x}
              key={index}
              delete={() => remove(index)}
              update={(eventName: string, timespan: string, action: string) =>
                updateEventActionRow(index, eventName, timespan, action)
              }
            />
          ))}
          <TableRow>
            <TableCell>
              <InputField fullwidth placeholder="Händelse" value={eventName} onChange={updateEventName} />
            </TableCell>
            <TableCell>
              <InputField fullwidth placeholder="Larmtid" value={timespan} onChange={updateTimespan} />
            </TableCell>
            <TableCell>
              <InputField fullwidth placeholder="Åtgärd" value={action} onChange={updateAction} />
            </TableCell>
            <TableCell>
              <IconButton color="primary" onClick={add} disabled={!isAddValid} aria-label="Lägg till rad">
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  );
}

interface IEventActionRowProps {
  isEditing: boolean;
  eventAction: ICoreSystemEventAction;
  delete: () => void;
  update: (eventName: string, timespan: string, action: string) => void;
}

const EventActionRow = (props: IEventActionRowProps) => {
  function updateEventName(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    props.update(e.target.value, props.eventAction.timespan, props.eventAction.action);
  }

  function updateTimespan(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    props.update(props.eventAction.eventName, e.target.value, props.eventAction.action);
  }

  function updateAction(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    props.update(props.eventAction.eventName, props.eventAction.timespan, e.target.value);
  }

  return (
    <TableRow>
      <TableCell>
        <InputField fullwidth placeholder="Händelse" value={props.eventAction.eventName} onChange={updateEventName} />
      </TableCell>
      <TableCell>
        <InputField fullwidth placeholder="Larmtid" value={props.eventAction.timespan} onChange={updateTimespan} />
      </TableCell>
      <TableCell>
        <InputField fullwidth placeholder="Åtgärd" value={props.eventAction.action} onChange={updateAction} />
      </TableCell>
      <TableCell>
        <IconButton color="error" onClick={props.delete} aria-label="Ta bort rad">
          <RemoveIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
