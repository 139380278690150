import React, { useCallback, useMemo } from 'react';
import IPaginatedResponse from '../../../../../../../../Shared/Api/response/IPaginatedResponse';
import { AsyncSelect, MultiAsyncSelect, ISelectOption } from '../../../../../../../../Shared/Select';
import { IProductExtended, useGetProducts } from '../Products.api';

export interface IProps {
  required?: boolean;
  systemTypeId?: number;
  regulationId?: number;
  onlyMainProducts?: boolean;
  selectedProduct?: ISelectOption | ISelectOption[];
  selectedProductId?: string | string[];
  label?: string;
  placeholder?: string;
  noLabel?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  mainProductId?: number;
  onChange?: (productId: string | string[] | undefined | null) => void;
  isDisabled?: boolean;
  withMoreInformation?: boolean;
  setProductsList?: (products: IPaginatedResponse<IProductExtended>) => void;
  noLimit?: boolean;
}

const ProductSelect = (props: IProps) => {
  const {
    mainProductId,
    systemTypeId,
    regulationId,
    onlyMainProducts,
    withMoreInformation,
    isMulti,
    selectedProduct,
    selectedProductId,
    setProductsList,
    noLimit
  } = props;
  const { getProducts, isLoading } = useGetProducts();
  const label = props.noLabel ? undefined : props.label ?? 'Välj produkt';

  const selectedOption = useMemo(() => {
    if (!selectedProduct) return undefined;
    if (isMulti) {
      return Array.isArray(selectedProduct) ? selectedProduct : [selectedProduct];
    } else {
      return Array.isArray(selectedProduct) ? selectedProduct[0] : selectedProduct;
    }
  }, [isMulti, selectedProduct]);

  const selectedOptionId = useMemo(() => {
    if (!selectedProductId) return undefined;
    if (isMulti) {
      return Array.isArray(selectedProductId) ? selectedProductId : [selectedProductId];
    } else {
      return Array.isArray(selectedProductId) ? selectedProductId[0] : selectedProductId;
    }
  }, [isMulti, selectedProductId]);

  const loadProducts = useCallback(
    async (query?: string) => {
      const response = await getProducts({
        pageNumber: 1,
        pageSize: noLimit ? 1000 : 25,
        query: query,
        mainProductId: mainProductId,
        systemTypeId: systemTypeId,
        regulationId: regulationId,
        onlyMainProducts: onlyMainProducts,
        usedAsSelectList: !withMoreInformation,
      });
      setProductsList && setProductsList(response);
      return response?.items.map((x) => ({ value: x.id.toString(), label: x.name }));
    },
    [getProducts, noLimit, mainProductId, systemTypeId, regulationId, onlyMainProducts, withMoreInformation, setProductsList],
  );

  if (props.isMulti) {
    return (
      <MultiAsyncSelect
        {...props}
        label={label}
        isLoading={isLoading}
        selectedOptions={selectedOption as ISelectOption[] | undefined}
        selectedOptionIds={selectedOptionId as string[] | undefined}
        loadOptions={loadProducts}
        onChange={(value) => props.onChange && props.onChange(value && value.map((x) => x.value))}
      />
    );
  }

  return (
    <AsyncSelect
      {...props}
      label={label}
      isLoading={isLoading}
      selectedOption={selectedOption as ISelectOption | undefined}
      selectedOptionId={selectedOptionId as string | undefined}
      loadOptions={loadProducts}
      onChange={(value) => props.onChange && props.onChange(value?.value)}
    />
  );
};
export default ProductSelect;
