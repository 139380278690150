import IPaginatedRequest from '../../../Shared/Api/request/IPaginatedRequest';
import IPaginatedResponse from '../../../Shared/Api/response/IPaginatedResponse';
import { useApiPost, useApiPut, useApiGet } from '../../../Core/Api/Api';
import { useCallback } from 'react';
import { AuditInterval, Service } from '../Errands/Components/Sale.api';

export interface IAuditListItem {
  agreementId: number;
  coreSystemId: number;
  customerNumber: number;
  customerId: number;
  customerName: string;
  address: string;
  postalCode: string;
  city: string;
  description: string;
  facilityId: string;
  facilityNumber: number;
  facilityName: string;
  systemType: string;
  agreement: string;
  startDate: Date;
  alarmClass: number | undefined;
  interval: AuditInterval | undefined;
  lastAudit: Date | undefined;
  nextAudit: Date;
  noAudit: boolean;
  hasBookedRevision: boolean;
  isOnTodoList: boolean;
}
export interface IAuditsListRequest extends IPaginatedRequest {
  listType: ListType;
  onlyMine?: boolean;
  auditFromDate?: Date;
  auditToDate?: Date;
  status?: string;
}

export function useGetAudits() {
  const { isLoading, response, post } = useApiPost<IPaginatedResponse<IAuditListItem>, IAuditsListRequest>(
    '/api/coresystem/audit/list',
  );

  return {
    isLoading,
    audits: response,
    getAudits: post,
  };
}

export enum ListType {
  Audit = 1,
  MissingAgreement = 3,
  NoContact = 4,
}

export interface IAuditForm {
  id: number;
  customerId: number;
  customerNumber: number;
  customerName: string;
  address: string;
  postalCode: string;
  city: string;
  facilityId: number;
  facilityName: string;
  identificationNumber: string;
  description: string;
  systemDate: Date;
  systemId: string;
  nextAudit?: Date;
  lastAudit?: Date;
  agreement: IAgreement | undefined;
  noAudit: boolean;
}

export interface IAgreement {
  id: number;
  description: string;
  startdate: Date;
  enddate: Date;
  auditInterval: AuditInterval;
  monthlyCost: number;
  service: Service;
  isActive: boolean;
  securityCompanySupplier: string;
  emergencyCallCenterSupplier: string;
  agreementNumber: number;
}

export function useGetAuditForm(coreSystemId: string | undefined) {
  const { isLoading, response, get } = useApiGet<IAuditForm>(`/api/coresystem/${coreSystemId}/audit/`, false);

  return {
    isLoading,
    auditForm: response,
    getAuditForm: get,
  };
}

export function useMoveAudit(customerId: string | undefined, coreSystemId: string | undefined) {
  const { isLoading, put } = useApiPut<any, IMoveAuditCommand>(
    `/api/customers/${customerId}/coresystem/${coreSystemId}/moveaudit`,
  );

  const moveAudit = useCallback(
    async (date: Date) => {
      await put({ date });
    },
    [put],
  );

  return {
    isLoading,
    moveAudit,
  };
}

export function useMoveAuditOnePeriod(customerId: string | undefined, coreSystemId: string | undefined) {
  const { isLoading, put } = useApiPut(`/api/customers/${customerId}/coresystem/${coreSystemId}/MoveAuditOnePeriod`);

  return {
    isLoading,
    moveAuditOnePeriod: put,
  };
}

export function useAddToWorkList(customerId: string | undefined, coreSystemId: string | undefined) {
  const { isLoading, put } = useApiPut(`/api/customers/${customerId}/coresystem/${coreSystemId}/addtoworklist`);

  return {
    isLoading,
    addToWorkList: put,
  };
}

export function useSetNoAudit(customerId: string | undefined, coreSystemId: string | undefined) {
  const { isLoading, put } = useApiPut(`/api/customers/${customerId}/coresystem/${coreSystemId}/noaudit`);

  return {
    isLoading,
    setNoAudit: put,
  };
}

interface ISetNoContact {
  noContact: boolean;
}

export function useSetNoContact(customerId: string | undefined, coreSystemId: string | undefined) {
  const { isLoading, put } = useApiPut<any, ISetNoContact>(
    `/api/customers/${customerId}/coresystem/${coreSystemId}/nocontact`,
  );

  return {
    isLoading,
    put,
  };
}

export function useMessageCustomer(customerId: string | undefined, coreSystemId: string | undefined) {
  const { isLoading, post } = useApiPost<any, IMessageCustomerCommand>(
    `/api/customers/${customerId}/coresystem/${coreSystemId}/messagecustomer`,
  );

  return {
    isLoading,
    messageCustomer: post,
  };
}

export interface IMessageCustomerCommand {
  message: string;
}

export interface IMoveAuditCommand {
  date: Date;
}
