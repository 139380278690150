import React, { useEffect, useState } from 'react';
import Container from '../../../../../../Shared/Container/Container';
import { InputField } from '../../../../../../Shared/InputFields/InputField/InputField';
import { ICustomerDto, useGetCustomer } from '../../../Customers.api';
import { ViewAddress } from '../../../../../../Shared/InputFields/Address/ViewAddress';
import { getCustomerTypeName } from '../../../../../../Shared/Misc/CustomerType';

interface ICustomerInfoDisplay {
  customerId: string;
  fullwidth?: boolean;
}

const CustomerInfoDisplay = (props: ICustomerInfoDisplay) => {
  const { isLoading, getCustomer } = useGetCustomer();
  const [customer, setCustomer] = useState<ICustomerDto>();

  useEffect(() => {
    async function loadCustomer() {
      const result = await getCustomer(props.customerId + '/false');
      setCustomer(result);
    }
    loadCustomer();
  }, [props.customerId, getCustomer]);

  return (
    <Container form label={'Kundinfo'} loading={isLoading} customSize={{xs: 12, md: props.fullwidth ? 12 : 6}}>
      <InputField label="Kundnamn" type="text" value={customer?.name} viewOnly />
      <InputField label="Kundtyp" type="text" value={customer ? getCustomerTypeName(customer.type) : ''} viewOnly />
      <InputField label="Person/Org nr" type="text" value={customer?.identificationNumber} viewOnly />
      <ViewAddress
        address={customer?.customerAddress ?? ''}
        postalCode={customer?.customerPostalCode ?? ''}
        city={customer?.customerCity}
      />
    </Container>
  );
};

export default CustomerInfoDisplay;
